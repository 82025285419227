import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, map } from 'rxjs';
import { Router } from '@angular/router';
import { LoginForm } from '../models/loginForm.model';
import { TokenService } from './token.service';
import { BYPASS_JW_TOKEN } from './request.interceptor';
import { TranslateService } from '@ngx-translate/core';
import { ClearDataService } from '@shared/services/clear-data.service';
import { AuthorizationService } from './authorization.service';
import { AppService } from '@shared/services/app.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  _apiURL = environment.API_URL;
  _loged: boolean;
  _translate = inject(TranslateService);
  clearData = inject(ClearDataService);
  _auth = inject(AuthorizationService);
  _app = inject(AppService);

  constructor(
    private _http: HttpClient,
    private _token: TokenService,
    private router: Router
  ) {
    this._loged = this.isLoggedIn;
  }

  login(user: LoginForm): Observable<any> {
    return this._http
      .post<LoginForm>(`${this._apiURL}/authorize/login`, user, {
        context: new HttpContext().set(BYPASS_JW_TOKEN, true),
      })
      .pipe(
        map((res: any) => {
          if (res.value && res.value.token) {
            this._loged = true;

            const {
              token,
              lstMenu,
              lstPermisos,
              apellidoYNombre,
              perfil,
              home,
              siglaIdioma,
              idUsuario,
              empresaPredeterminada,
            } = res.value;

            localStorage.setItem('token', token);
            localStorage.setItem(
              'empresa',
              JSON.stringify(empresaPredeterminada)
            );
            localStorage.setItem('idUsuario', idUsuario);
            localStorage.setItem('apellidoYNombre', apellidoYNombre);
            localStorage.setItem('perfil', perfil);
            localStorage.setItem('menu', btoa(JSON.stringify(lstMenu)));
            localStorage.setItem('permisos', btoa(JSON.stringify(lstPermisos)));
            localStorage.setItem(
              'lang',
              siglaIdioma === 'es' ? 'es-ES' : 'en-US'
            );
            this._app.sidebar.menuItems.set(lstMenu);
            this._token.token = res.value.token;
            this._auth.getPermission();
          }
        })
      );
  }

  unAuhtorized() {
    if (!this._token.validateToken()) this.logout();
  }

  logout() {
    this.clearStoreData();
    this._auth.permission = [];
    this._loged = false;
    this.clearData.clearData();
    this.router.navigate(['/auth/login']);
  }

  get isLoggedIn() {
    return this._token.validateToken();
  }

  clearStoreData() {
    localStorage.removeItem('token');
    localStorage.removeItem('menu');
    localStorage.removeItem('permisos');
    localStorage.removeItem('empresa');
    localStorage.removeItem('idUsuario');
    localStorage.removeItem('apellidoYNombre');
  }
}
